/* eslint-disable @typescript-eslint/no-explicit-any */
/* https://material-ui.com/components/autocomplete/#google-maps-place */

import { useEffect, useMemo, useRef, useState } from "react"

import { GoogleAPI, GoogleApiWrapper } from "google-maps-react"

import { useTranslate } from "src/i18n/useTranslate"
import { Combobox } from "src/ui/Combobox/Combobox"
import PinIcon from "src/ui/icons/pin.svg"
import { TInputContainerProps } from "src/ui/InputContainer/InputContainer"

interface IGoogleServices {
  AutoCompleteService: google.maps.places.AutocompleteService | undefined
  GeoCoder: google.maps.Geocoder | undefined
}

export interface IOnLocationSelectedResult {
  lat: number | undefined
  lng: number | undefined
  address: string
  addressComponents: google.maps.GeocoderAddressComponent[] | null
}

type TOnlocationSelected = (arg: IOnLocationSelectedResult) => void

const googleLoaded = () => {
  return Boolean((window as any).google)
}

/**
 * Get more details about the place with the help of PlacesService after an auto-complete prediction place_id
 */
function getAddressLocation(
  address: google.maps.places.AutocompletePrediction,
  callback: TOnlocationSelected,
  mapRef: React.RefObject<HTMLDivElement>
) {
  const placeId = address.place_id
  if (!placeId) return
  if (!mapRef.current) return

  // https://developers.google.com/maps/documentation/javascript/examples/place-details#maps_place_details-typescript
  // There is currently no formatted street_name1 address returned by Autocomplete.
  // We use the PlacesService to get adr_address which contains a formatted street_name1 address in a string HTML element.
  const placesService = new google.maps.places.PlacesService(mapRef.current)

  const request = {
    placeId,
    fields: ["geometry", "address_components", "adr_address"],
  }

  placesService.getDetails(
    request,
    (
      place: google.maps.places.PlaceResult | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry?.location &&
        place.adr_address &&
        place.address_components
      ) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        const addressComponents = place.address_components

        // We check if street_address is part of the results in addressComponents.
        const checkStreetAddress = addressComponents.find((component) =>
          component.types.includes("street_address")
        )

        // PlacesServices returns a place.adr_address containing a formatted street_name1 address as a string element in the format
        // "<span class="street-address">Baltzarsgatan 23</span>, <span class="postal-code">211 36</span> ..."
        const virtualElement = new DOMParser().parseFromString(
          place.adr_address,
          "text/html"
        )
        const streetAddress = virtualElement
          .getElementsByClassName("street-address")
          .item(0)?.textContent

        // If there is a formatted street_name1 address in place.adr_address but no street address in address_components,
        // we add the formatted street_name1 to the addressComponents array.
        !checkStreetAddress &&
          !!streetAddress &&
          addressComponents.push({
            long_name: streetAddress,
            short_name: streetAddress,
            types: ["street_address"],
          })

        callback({
          lat,
          lng,
          address: address.description,
          addressComponents,
        })
      }
    }
  )
}

const googleServices: IGoogleServices = {
  AutoCompleteService: undefined,
  GeoCoder: undefined,
}

function PlacesAutoComplete({
  label,
  inputValue = "",
  setInputValue,
  onLocationSelected,
  google: _googleApi, // alias 'google' from 'google-maps-react' to googleApi
  required = false,
}: // TODO WEB-378: Determine if we should use the 'googleApi' prop instead of window.google
{
  inputValue: string
  setInputValue: (value: string) => void
  onLocationSelected: TOnlocationSelected
  google: GoogleAPI
  required?: boolean
} & TInputContainerProps) {
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const loaded = useRef(false)
  const mapRef = useRef<HTMLDivElement>(null)

  const { t, langKeys } = useTranslate()

  loaded.current = Boolean(google)

  const onSelected = (
    addressPrediction: google.maps.places.AutocompletePrediction | null
  ) => {
    if (!addressPrediction) {
      return
    }
    getAddressLocation(addressPrediction, onLocationSelected, mapRef)
  }

  const fetch = useMemo(
    () =>
      throttle((input: any, callback: any): void => {
        googleServices.AutoCompleteService?.getPlacePredictions(input, callback)
      }, 200),
    []
  )

  useEffect(() => {
    let active = true

    if (!googleServices.AutoCompleteService && googleLoaded()) {
      googleServices.AutoCompleteService =
        new google.maps.places.AutocompleteService()
      googleServices.GeoCoder = new google.maps.Geocoder()
    }

    if (!googleServices.AutoCompleteService) {
      return undefined
    }

    if (inputValue === "") {
      setOptions([])
      return undefined
    }

    fetch(
      { input: inputValue },
      (results: google.maps.places.AutocompletePrediction[]) => {
        if (active) {
          setOptions(results || [])
        }
      }
    )

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  return (
    <>
      <Combobox
        label={label ?? t(langKeys.address)}
        /* 
          This prop is not used to highlight the current selected atm, 
          it would require a rewrite of this component and it's caller to pass down `placeId`
          Also it may not be worth it in this case to highlight the selected 
          becuase the selected label will not match the title of the option
         */
        selectedValue={inputValue}
        options={options.map((option) => ({
          label: option.structured_formatting.main_text,
          description: option.structured_formatting.secondary_text,
          value: option.place_id,
          icon: PinIcon,
          selectedLabelText: option.description,
        }))}
        onChange={(value) => {
          const selectedPrediction =
            options.find((option) => option.place_id === value) ?? null

          onSelected(selectedPrediction)
        }}
        input={inputValue}
        onInput={(value) => {
          setInputValue(value)
        }}
        required={required}
        requiredIndicator
      />
      <div ref={mapRef}></div>
    </>
  )
}

function throttle(func: (input: any, callback: any) => void, wait: number) {
  let isWaiting = false
  return function (input: any, callback: any) {
    if (isWaiting) {
      return
    }
    func(input, callback)
    isWaiting = true
    setTimeout(function () {
      isWaiting = false
    }, wait)
  }
}

// eslint-disable-next-line import/no-default-export
export const GooglePlacesAutoComplete = GoogleApiWrapper({
  apiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
})(PlacesAutoComplete)
// TODO WEB-380: Determine if we should type this? It will look horrible:
// https://stackoverflow.com/questions/59689535/how-to-pass-children-type-to-props-of-hoc-react
